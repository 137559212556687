export const subHeaderSecondUnit = [
  {
    name: "Pronësia",
    href: "/unit-2/part-a",
    isPage: false
  },
  {
    name: "Caktimi i axhendës",
    href: "/unit-2/part-b",
    isPage: false
  }
];

export const subHeaderThirdUnit = [
  {
    name: "Si funksionojnë mediat sociale",
    href: "/unit-3/part-a",
    isPage: false
  },
  {
    name: "Shtrembërimet konjitive",
    href: "/unit-3/part-b",
    isPage: false
  }
];

export const subHeaderFourthUnit = [
  {
    name: "Çfarë është dezinformimi?",
    href: "/unit-4/part-a",
    isPage: false
  },
  {
    name: "Manipulimi",
    href: "/unit-4/part-b",
    isPage: false
  },
  {
    name: "Stereotipat dhe gjuha e urrejtjes",
    href: "/unit-4/part-c",
    isPage: false
  },
  {
    name: "Sindroma e Botës së Ligë",
    href: "/unit-4/part-d",
    isPage: false
  }
];
