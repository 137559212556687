<template>
  <div class="flexi-accordion">
    <slot name="title" v-bind:inputData="inputData">
      <span
        v-text="inputData.title"
        @click="toggleExpand"
        class="flexi-accordion__title"
        :class="{ active: expandWidget }"
      />
    </slot>
    <div class="flexi-accordion__container" :class="{ expanded: expandWidget }">
      <slot name="subtitle" v-bind:inputData="inputData">
        <span
          v-if="options.subTitle"
          v-text="inputData.subTitle"
          class="flexi-accordion__container--subtitle"
        />
      </slot>
      <slot name="paragraph" v-bind:inputData="inputData">
        <p
          v-if="options.paragraph"
          v-html="inputData.paragraph"
          class="flexi-accordion__container--paragraph"
        />
      </slot>
      <slot name="img" v-bind:inputData="inputData">
        <img
          v-if="options.img"
          :src="inputData.img"
          class="flexi-accordion__container--img"
        />
      </slot>
      <slot name="button" v-bind:inputData="inputData">
        <a
          v-if="options.button"
          @click="handleClick(inputData.buttonLink)"
          class="flexi-accordion__container--button"
        >
          <span v-text="inputData.buttonText" />
        </a>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "flexi-accordion",
  props: {
    inputData: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {
        return {
          options: {
            subTitle: true,
            paragraph: true,
            img: false,
            button: false
          }
        };
      }
    }
  },
  data() {
    return {
      expandWidget: false
    };
  },
  methods: {
    handleClick(href) {
      this.$emit("button-clicked", href);
    },
    toggleExpand() {
      this.expandWidget = !this.expandWidget;
    }
  }
};
</script>
