<template>
  <section class="flexi-top-card__wrapper" :id="inputData.string_id">
    <div class="flexi-top-card__wrapper--media">
      <slot name="media" v-bind="{ inputData }">
        <img class="image" :src="inputData.img" />
        <div v-if="inputData.video" class="video__wrapper">
          <video class="video__wrapper--item" autoplay playsinline muted loop>
            <source :src="inputData.video" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </slot>
    </div>
    <div class="flexi-top-card__wrapper--content">
      <slot name="content" v-bind="{ inputData, handleClick }">
        <h1 class="title" v-text="inputData.title" />
        <div class="subtitle" v-text="inputData.subTitle" />
        <div class="quote" v-text="inputData.quote" />
        <div class="button__wrapper">
          <flexi-button
            v-if="inputData.buttonText"
            :text="inputData.buttonText"
            @button-clicked="handleClick(inputData.buttonLink)"
          />
          <flexi-button
            v-if="!user"
            :text="'Hyrje'"
            @button-clicked="handleClick('#login')"
          />
        </div>
      </slot>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FlexiTopcard",
  props: {
    inputData: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    handleClick(buttonLink) {
      this.$emit("button-clicked", buttonLink);
    }
  }
};
</script>
