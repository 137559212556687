import Vue from "vue";
import VeeValidate from "vee-validate";
import certificateValidator from "./uniqueCertificate";
import emailValidator from "./uniqueEMail";

Vue.use(VeeValidate);
certificateValidator.init();
emailValidator.init();

VeeValidate.Validator.localize({
  en: {
    messages: {
      required: () => "*Fusha është e nevojshme",
      email: () => "*Email -i duhet të jetë i vlefshëm",
      min: () => "*Fusha duhet të përmbajë të paktën 1 karakter"
    }
  }
});
