<template>
  <header class="flexi-header__wrapper">
    <div class="flexi-header__wrapper--logo">
      <slot name="logo">
        <img
          src="https://app.imgforce.com/images/user/zrC_1622176244_logo-black-120.png"
          @click="navigateToRoute('/')"
        />
      </slot>
    </div>
    <div class="flexi-header__wrapper--navigation">
      <slot
        name="navigation"
        v-bind="{
          inputData,
          toggleTheme,
          changeLanguage,
          toggleBurger,
          navigateToExternalPage,
          navigateToRoute,
          isNavOpen
        }"
      >
        <flexi-desktop-navigation
          :navigation="inputData"
          :navigateToExternalPage="navigateToExternalPage"
          :navigateToRoute="navigateToRoute"
        />
      </slot>
      <flexi-theme-switcher :toggleTheme="toggleTheme" />
      <flexi-language-switcher :changeLanguage="changeLanguage" />
      <slot
        name="mobile"
        v-bind="{
          inputData,
          toggleTheme,
          changeLanguage,
          toggleBurger,
          navigateToExternalPage,
          navigateToRoute,
          isNavOpen
        }"
      >
        <flexi-mobile-navigation
          :navigation="inputData"
          :isNavOpen="isNavOpen"
          :toggleBurger="toggleBurger"
          :navigateToExternalPage="navigateToExternalPage"
          :navigateToRoute="navigateToRoute"
        />
      </slot>
      <slot name="user"> </slot>
    </div>
  </header>
</template>

<script>
export default {
  name: "flexi-header",
  props: {
    inputData: {
      type: Array
    },
    navigateToRoute: {
      type: Function
    },
    toggleTheme: {
      type: Function
    },
    changeLanguage: {
      type: Function
    }
  },
  data() {
    return {
      isNavOpen: false
    };
  },
  methods: {
    toggleBurger() {
      this.isNavOpen = !this.isNavOpen;
    },
    navigateToExternalPage(item) {
      window.open(item, "_blank").focus();
    }
  }
};
</script>
