<template>
  <div id="scorring-quiz" class="flexi-scorring-quiz__wrapper">
    <div class="flexi-scorring-quiz__wrapper--header">
      <div class="title" v-text="'Provim përfundimtar'" />
    </div>

    <div
      class="flexi-scorring-quiz__wrapper--content"
      v-if="!showTotalScore && !user.certificate.certificate"
    >
      <form novalidate data-vv-scope="form" id="form" class="form">
        <div class="form-field__wrapper">
          <div class="form-field__wrapper--content">
            <label>Email *</label>
            <input
              @paste.prevent
              v-model="form.email"
              v-validate="{
                required: true,
                email: true,
                uniqueCertificate: true
              }"
              :class="{ 'valid-input': errors.has('form.Email') }"
              class="form-field__wrapper--input"
              type="text"
              name="Email"
              placeholder="Email -i juaj"
              required
              disabled
            />
            <validation-message
              :message="errors.first('form.Email')"
              :show="errors.has('form.Email')"
            />
          </div>
        </div>

        <div class="form-field__wrapper">
          <div class="form-field__wrapper--content">
            <label>Emri dhe Mbiemri *</label>
            <input
              @paste.prevent
              v-model="form.fullName"
              v-validate="{ required: true, min: 1, max: 150 }"
              :class="{ 'valid-input': errors.has('form.Emri dhe Mbiemri') }"
              class="form-field__wrapper--input"
              type="text"
              name="Emri dhe Mbiemri"
              placeholder="Emri dhe Mbiemri"
              required
            />
            <validation-message
              :message="errors.first('form.Emri dhe Mbiemri')"
              :show="errors.has('form.Emri dhe Mbiemri')"
            />
          </div>
        </div>

        <div class="form-field__wrapper">
          <div class="form-field__wrapper--content">
            <label>Qyteti *</label>
            <input
              @paste.prevent
              v-model="form.city"
              v-validate="{ required: true, min: 1, max: 150 }"
              :class="{ 'valid-input': errors.has('form.Qyteti') }"
              class="form-field__wrapper--input"
              type="text"
              name="Qyteti"
              placeholder="Qyteti"
              required
            />
            <validation-message
              :message="errors.first('form.Qyteti')"
              :show="errors.has('form.Qyteti')"
            />
          </div>
        </div>

        <div
          class="questions__wrapper"
          v-for="(question, questionIndex) in inputData.questions"
          :key="'question-' + questionIndex"
        >
          <div class="questions__wrapper--content">
            <div class="question" v-text="question.question" />
            <div class="points" v-text="calcuatePoints(question.answers)" />
          </div>
          <div
            class="questions__wrapper--answer"
            v-for="(answer, answerIndex) in question.answers"
            :key="'answer-' + answerIndex"
          >
            <div class="checkbox__wrapper">
              <input
                type="checkbox"
                class="itemCheck mb-0"
                @input="markTheCheckbox(questionIndex, answerIndex)"
                v-model="questions[questionIndex].answers[answerIndex].correct"
                :disabled="
                  checkIfChecked(
                    question.multi_answer,
                    questions[questionIndex],
                    questionIndex,
                    answerIndex
                  )
                "
              />
            </div>
            <div class="answer" v-text="answer.answer" />
          </div>
        </div>

        <div class="button__wrapper">
          <button
            class="button__wrapper--item"
            :class="{ disabled: processing }"
            :disabled="processing"
            @click.prevent="submitQuiz"
          >
            <spring-spinner
              v-if="processing"
              :animation-duration="2000"
              :size="36"
              color="#fc5000"
            />
            <span v-else class="button__text" v-text="'Paraqit quiz'" />
          </button>
        </div>
      </form>
    </div>

    <div class="flexi-scorring-quiz__wrapper--content" v-else>
      <div class="total-score__wrapper">
        <div
          class="total-score__wrapper--score"
          v-text="'Rezultati i përgjithshëm: ' + score.finalScore + ' pikë!'"
        />
        <div
          v-if="user.certificate.certificate"
          class="total-score__wrapper--score"
          v-text="'Punë e mrekullueshme, e mirë!'"
        />
        <div
          v-else
          class="total-score__wrapper--score"
          v-text="'Mos u dorëzoni, kërkoni përgjigjen e saktë!'"
        />

        <div class="total-score__wrapper--buttons__wrapper">
          <button
            v-if="user.certificate.certificate"
            class="button"
            v-text="'Shkarkoni certifikatën'"
            @click="downloadCertificate(user.certificate.certificate)"
          />
          <button
            v-if="!user.certificate.certificate"
            class="button"
            v-text="'Kuizin e rifillimit'"
            @click="restartQuiz"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "flexi-scorring-quiz",
  props: {
    inputData: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["selectedAnswersPopUp", "score", "user"]),
    totalPoints() {
      var points = [];
      this.inputData.questions.filter(question => {
        question.answers.filter(answer => {
          if (answer.correct) {
            points.push(1);
          }
        });
      });
      return points.length * 2;
    }
  },
  data() {
    return {
      generatedCertificate: false,
      form: {
        email: "",
        fullName: "",
        city: "",
        scorePogress: null,
        progress: null,
        last_unit: null,
        totalScore: null,
        questions: null,
        popUpQuestions: null
      },
      questions: [],
      processing: false,
      showTotalScore: false
    };
  },
  methods: {
    ...mapActions(["generateCertificate", "setFinalScore"]),
    calcuatePoints(answers) {
      const score = answers.filter(answer => {
        return answer.correct;
      });
      return score.length * 2 + " pikë";
    },
    calculateScore() {
      var score = [];
      for (let i = 0; i < this.inputData.questions.length; i++) {
        for (let j = 0; j < this.inputData.questions[i].answers.length; j++) {
          if (
            this.inputData.questions[i].answers[j].correct ==
            this.questions[i].answers[j].correct
          ) {
            score.push(this.questions[i].answers[j].correct);
          }
        }
      }

      var total = score.filter(elem => {
        return elem === true;
      });

      this.form.totalScore = total.length * 2;
      this.setFinalScore(this.form.totalScore);
      this.form.questions = this.questions;
      this.form.popUpQuestions = this.selectedAnswersPopUp;
      this.form.last_unit = this.score.last_unit;
      this.form.progress = this.score.progress;
      this.form.scorePogress = this.score.scorePogress;
    },
    checkIfChecked(multiAnswers, question, questionIndex, answerIndex) {
      if (!multiAnswers) {
        return (
          question.answers.some(elem => elem.correct) &&
          !this.questions[questionIndex].answers[answerIndex].correct
        );
      } else {
        return false;
      }
    },
    markTheCheckbox(questionIndex, answerIndex) {
      this.questions[questionIndex].answers[answerIndex].correct = !this
        .questions[questionIndex].answers[answerIndex].correct;
    },
    downloadCertificate(certificate) {
      window.open(certificate, "_blank").focus();
    },
    issueCertificate() {
      this.generateCertificate(this.form).then(result => {
        if (result) {
          this.showTotalScore = true;
          this.processing = false;
          this.generatedCertificate = true;
          this.smoothScroll("scorring-quiz");
        }
      });
    },
    resetForm() {
      this.form.progress = null;
      this.form.scorePogress = null;
      this.form.last_unit = null;
      this.form.totalScore = null;
      this.form.questions = null;
      this.form.popUpQuestions = null;
    },
    restartQuiz() {
      if (!this.user.certificate.certificate) {
        this.generatedCertificate = false;
        this.showTotalScore = false;
        this.setRawQuestionsArray();
        this.resetForm();
      }
    },
    setRawQuestionsArray() {
      const questions = JSON.parse(JSON.stringify(this.inputData.questions));
      questions.filter(question => {
        return question.answers.filter(answer => {
          answer.correct = false;
        });
      });
      this.questions = questions;
    },
    setUserEmail() {
      if (this.user) {
        this.form.email = this.user.email;
      }
    },
    submitQuiz() {
      this.$validator.validateAll("form").then(result => {
        if (result) {
          this.processing = true;
          this.calculateScore();

          if (this.form.totalScore > this.totalPoints / 2 + 5) {
            this.issueCertificate();
          } else {
            this.showTotalScore = true;
            this.generatedCertificate = false;
            this.processing = false;
            this.smoothScroll("scorring-quiz");
          }
        } else {
          this.smoothScroll("scorring-quiz");
        }
      });
    }
  },
  mounted() {
    this.setRawQuestionsArray();
    this.setUserEmail();
  }
};
</script>
