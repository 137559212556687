export const unitsNavigation = [
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/",
    id: 1,
    isLink: 0,
    name: "Faqja Kryesore",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z"
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-1",
    id: 2,
    isLink: 0,
    name: "Kapitulli 1",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z"
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-2",
    id: 3,
    isLink: 0,
    name: "Kapitulli 2",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z",
    dropdown_menu: [
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-2/part-a",
        id: 1,
        isLink: 0,
        name: "Pjesa A: Pronësia",
        tooltip: "/unit-2/part-a",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-2/part-b",
        id: 1,
        isLink: 0,
        name: "Pjesa B: Caktimi i axhendës",
        tooltip: "/unit-2/part-b",
        updated_at: "2021-10-14T00:01:51.000000Z"
      }
    ]
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-3",
    id: 4,
    isLink: 0,
    name: "Kapitulli 3",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z",
    dropdown_menu: [
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-3/part-a",
        id: 1,
        isLink: 0,
        name: "Pjesa A: Si funksionojnë mediat sociale",
        tooltip: "/unit-3/part-a",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-3/part-b",
        id: 1,
        isLink: 0,
        name: "Pjesa B: Shtrembërimet konjitive",
        tooltip: "/unit-3/part-b",
        updated_at: "2021-10-14T00:01:51.000000Z"
      }
    ]
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-4",
    id: 5,
    isLink: 0,
    name: "Kapitulli 4",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z",
    dropdown_menu: [
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-4/part-a",
        id: 1,
        isLink: 0,
        name: "Pjesa A: Çfarë është dezinformimi?",
        tooltip: "/unit-4/part-a",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-4/part-b",
        id: 2,
        isLink: 0,
        name: "Pjesa B: Manipulimi",
        tooltip: "/unit-4/part-b",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-4/part-c",
        id: 3,
        isLink: 0,
        name: "Pjesa C: Stereotipat dhe gjuha e urrejtjes",
        tooltip: "/unit-4/part-c",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-4/part-d",
        id: 2,
        isLink: 0,
        name: "Pjesa D: Sindroma e Botës së Ligë",
        tooltip: "/unit-4/part-d",
        updated_at: "2021-10-14T00:01:51.000000Z"
      }
    ]
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-5",
    id: 6,
    isLink: 0,
    name: "Kapitulli 5",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z"
  }
];
