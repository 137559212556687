export const unitsSections = [
  {
    firstUnit: [
      {
        title: "Mirëserdhët në program",
        string_id: "welcome-to-the-course",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Ekosistemi i informacionit",
        string_id: "information-ecosystem",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png"
      },
      {
        title: "6 llojet e përmbajtjes mediatike",
        string_id: "types-of-content",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Faktet Kundër Opinionit",
        string_id: "facts-vs-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      },
      {
        title: "Sa ndërveproni me mediat?",
        string_id: "consumption-log",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      },
      {
        title: "Emërtoje dhe Kontrolloje",
        string_id: "name-it-to-tame-it",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -50
      }
    ],
    secondUnitPartA: [
      {
        title: "Lloji i medias",
        string_id: "types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Pronësia: Media private dhe joprivate",
        string_id: "private-non-private-media",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -550
      },
      {
        title: "Media online",
        string_id: "private-online-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Media shtetërore në Shqipëri",
        string_id: "state-media-in-Albania",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Media sociale dhe politikanët",
        string_id: "social-media-and-politicians",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Pronësia: Transmetimi publik",
        string_id: "ownership-and-broadcasting",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Media joprivate/ jo për qëllime fitimi/ komunitare",
        string_id: "non-private-non-for-profit",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Çfarë lloje mediash kemi në Shqipëri?",
        string_id: "what-types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    secondUnitPartB: [
      {
        title: "Si vjen një histori te ne?",
        string_id: "how-does-a-story",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Politika editoriale",
        string_id: "editorial-policy",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Standardet e gazetarisë",
        string_id: "journalistic-standards",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Liria e shtypit dhe censura",
        string_id: "freedom-of-press",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Përfundim",
        string_id: "conclusion",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: 0
      }
    ],
    thirdUnitPartA: [
      {
        title: "Mediat sociale si burim lajmesh",
        string_id: "social-media-as-a-newssource",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title:
          "Sa di Facebook-u rreth nesh dhe disa mënyra për ta kufizuar atë",
        string_id: "how-to-check",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Kuic: Algoritmet në mediat sociale",
        string_id: "quiz-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "TED Talk: Kujdes nga flluskat filtruese online",
        string_id: "ted-talk",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    thirdUnitPartB: [
      {
        title: "Shtrembërimet konjitive",
        string_id: "cognitive-distortions",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Anshmëritë e zakonshme",
        string_id: "common-biases",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartA: [
      {
        title: "Llojet e çrregullimit të informacionit",
        string_id: "types-of-information-disorder",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Vëmendje ndaj mashtrimeve",
        string_id: "attention-hacking",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabA: [
      {
        title: "Titujt dhe fotot e manipuluara",
        string_id: "tab-a-headlines",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -300
      },
      {
        title: "Titulli: Nga se të ruheni",
        string_id: "tab-a-headlines-things-to-look-out-for",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -50
      },
      {
        title: "Fotot e manipuluara - Kini kujdes!",
        string_id: "tab-a-manipulated-photos-watch-out",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Tre lloje manipulimi dhe si t’i dallojmë ato",
        string_id: "tab-a-types-of-manipulation",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabB: [
      {
        title: "Bots-et & Trolls-at",
        string_id: "tab-b-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Hilet e jetës: Si t’i zbuloni Bots-et dhe Trolls-et",
        string_id: "tab-b-life-hacks-how-to-detect-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -250
      }
    ],
    fourthUnitPartBTabC: [
      {
        title: "Opinioni i ekspertëve të rremë",
        string_id: "tab-c-fake-expert-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -300
      },
      {
        title: "Advertorialet",
        string_id: "tab-c-advertorial",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -800
      },
      {
        title: "Rregullat e reklamave në Shqipëri",
        string_id: "tab-c-regulation-of-advertising-in-albania",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -250
      }
    ],
    fourthUnitPartBTabD: [
      {
        title: "Manipulimi i burimit të origjinës",
        string_id: "tab-d-manipulation-with-the-original-source",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Manipulimi në mediat sociale",
        string_id: "tab-d-manipulation-in-social-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -50
      }
    ],
    fourthUnitPartBTabE: [
      {
        title: "Mjete për verifikimin e fakteve",
        string_id: "tab-e-fact-checking-tools",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartC: [
      {
        title: "Gjuha e urrejtjes përballë lirisë së shprehjes",
        string_id: "hate-speach",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title:
          "Si ta raportojmë gjuhën e urrejtjes dhe përmbajtje të tjera të dëmshme dhe joetike mediatike",
        string_id: "how-to-report-hate-speech",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title:
          "Si ta diktoni dhe shmangni gjuhën e urrejtjes - për gazetarët dhe krijuesit e përmbajtjeve mediatike",
        string_id: "how-to-detect-and-avoid-hate-speech",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -50
      },
      {
        title: "Kuic: Gjuhë përfshirëse",
        string_id: "quizz-inclusive-language",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -150
      }
    ],
    fourthUnitPartD: [
      {
        title: "Çfarë është Sindroma e Botës së Ligë?",
        string_id: "what-is-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Instinkti i frikës",
        string_id: "the-fear-instinct",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "A i kontrollojmë ne vendimet tona?",
        string_id: "what-should-we-do-to-overcome-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      }
    ],
    fifthUnit: [
      {
        title: "Pesë këshilla për të mbrojtur veten nga dezinformimi",
        string_id: "five-tips-to-protect-yourself",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Mbani mend!",
        string_id: "keep-in-mind",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      }
    ]
  }
];
