var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPage),expression:"showPage"}],staticClass:"flexi-container page__home"},[_c('div',{staticClass:"password-section"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("Vendosni fjalëkalimin tuaj të ri")]),_c('form',{attrs:{"novalidate":"","data-vv-scope":"password-form","id":"password-form"},on:{"submit":function($event){$event.preventDefault();return _vm.setNewClientPassword.apply(null, arguments)}}},[_c('div',{staticClass:"modal-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"},{name:"validate",rawName:"v-validate",value:({
              required: true,
            }),expression:"{\n              required: true,\n            }"}],staticClass:"form-field__wrapper--input disabled",class:{ 'valid-input': _vm.errors.has('password-form.Email') },attrs:{"type":"text","name":"Email","placeholder":"Email -i juaj","required":"","disabled":""},domProps:{"value":(_vm.form.email)},on:{"paste":function($event){$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('validation-message',{attrs:{"message":_vm.errors.first('password-form.Email'),"show":_vm.errors.has('password-form.Email')}})],1),_c('div',{staticClass:"modal-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"},{name:"validate",rawName:"v-validate",value:({
              required: true,
              min: 4,
              max: 100,
            }),expression:"{\n              required: true,\n              min: 4,\n              max: 100,\n            }"}],staticClass:"form-field__wrapper--input",class:{
              'valid-input': _vm.errors.has('password-form.Password'),
            },attrs:{"type":"password","name":"Password","placeholder":"Fjalëkalimin","required":""},domProps:{"value":(_vm.form.password)},on:{"paste":function($event){$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('validation-message',{attrs:{"message":_vm.errors.first('password-form.Password'),"show":_vm.errors.has('password-form.Password')}})],1),_c('button',{staticClass:"flexi-btn"},[(!_vm.processing)?_c('span',[_vm._v("Vendosur")]):_c('spring-spinner',{staticClass:"spinner",attrs:{"animation-duration":2000,"size":25,"color":"#fc5000"}})],1)]),(_vm.showWarning)?_c('div',{staticClass:"invalid-form warning-login__wrapper"},[_c('span',{staticClass:"warning-login__wrapper--item",domProps:{"textContent":_vm._s('*Adresa e emailit është rivendosur tashmë!')}})]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":"https://app.imgforce.com/images/user/xS2_1637269795_01x500.gif"}})])}]

export { render, staticRenderFns }